
.top-header-sm {
  display: none; 
 }

@media screen and (max-width: 1183px) {
    .top-header {
     display: none; 
    }

    .top-header-sm {
      display: flex; 
     }
  }